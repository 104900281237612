export default [
  {
    header: 'Tableau de bord',
    icon: 'HomeIcon',
    children: [
      {
        title: 'Général',
        route: 'dashboard-index',
        icon: 'GridIcon',
      },
      {
        title: 'Statistiques',
        icon: 'ActivityIcon',
        children: [
          {
            title: 'Finance',
            route: 'dashboard-statistics-finance',
            icon: 'DollarSignIcon',
          },
          {
            title: 'Chiffres',
            route: 'dashboard-statistics-numbers',
            icon: 'CircleIcon',
          },
          {
            title: "Chargé d'affaire",
            route: 'dashboard-statistics-ca',
            icon: 'ActivityIcon',
          },
          {
            title: 'Ressource Humaine',
            route: 'dashboard-statistics-bdp',
            icon: 'ActivityIcon',
          },
          {
            title: 'Points focaux',
            route: 'points-focaux',
            icon: 'ActivityIcon',
          },
        ],
      },
    ],
  },
  {
    title: 'Commandes',
    icon: 'ShoppingBagIcon',
    route: 'admin-orders',
  },
  {
    title: 'Prestataires',
    icon: 'UserCheckIcon',
    route: 'admin-work-providers',
  },
  {
    title: 'Clients',
    icon: 'UsersIcon',
    route: 'admin-customers',

  },
  {
    header: 'Services',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: 'Ponctuels',
        route: 'admin-services',
        icon: 'RotateCwIcon',
      },
      {
        title: 'Récurrents',
        route: 'admin-direct-services',
        icon: 'RepeatIcon',
      },
    ],
  },
  {
    header: 'Admin & Rôles',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Admins',
        route: 'admin-users',
        icon: 'UsersIcon',
      },
      {
        title: 'Rôles',
        route: 'admin-access',
        icon: 'CheckSquareIcon',
      },
    ],
  },

]
