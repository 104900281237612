<template>
  <div>
    <layout-horizontal>
      <router-view />

      <template #navbar="{ toggleVerticalMenuActive }">
        <app-navbar-horizontal-layout-brand />
        <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
      </template>
    </layout-horizontal>
  </div>
</template>

<script>
import LayoutHorizontal from '@core/layouts/layout-horizontal/LayoutHorizontal.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import AppNavbarHorizontalLayoutBrand from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue'
import { mapActions, mapGetters } from 'vuex'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Navbar from '../components/Navbar.vue'

export default {
  components: {
    LayoutHorizontal,
    // AppCustomizer,
    Navbar,
    AppNavbarHorizontalLayoutBrand,
  },

  data() {
    return {}
  },

  computed: {
    ...mapGetters('orders', [
      'getOrders',
      'getDirectOrders',
      'getNotCompletedDirectOrders',
      'getArchivedDirectOrders',
    ]),
    ...mapGetters('professionals', [
      'getProfessionals',
      'getEmployees',
      'getPayedEmployees',
      'getUnpayedEmployees',
      'getPayedPros',
      'getUnpayedPros',
    ]),
  },

  watch: {},

  async created() {
    const token = localStorage.getItem('token')
    const user = JSON.parse(localStorage.getItem('user'))
    this.$store.commit('auth/SET_USER', user)
    this.$store.commit('auth/SET_TOKEN', token)

    switch (user.role.slug) {
      case 'super-administrateur':
        this.applyGetDirectOrdersUnformedEmployeesAction()
        this.applyGetServicesAction()
        this.applyGetOrdersAction()
        this.applyGetProfessionalsAction()
        this.applyGetCustomersAction()
        await this.getBestProfessionalsAction()
        await this.getMostRequestedServicesAction()
        await this.getMostRequestedRecurringServicesAction()
        this.applyGetProPerServiceAndAdressAction()
        this.applyGetSatisfiedOrderAction()
        this.applyGetUnsatisfiedOrderAction()
        this.applyGetProAmountAction()
        this.applyGetCommissionAction()
        this.applyGetDirectOrdersSalaryPayments()
        this.applyGetCustomerConversionAction()
        this.applyGetProAmountPerServiceAction()
        this.applyGetCanceledOrdersAction()
        this.applyGetDateFinanceStatisticsAction()
        this.applyGetMonthFinanceStatisticsAction()
        this.applyGetNumbersStatisticsAction()
        this.applyGetDirectOrderAction()
        this.applyGetRecurringServicesAction()
        this.applyGetEmployeesAction()
        // this.applyGetPayedEmployeesAction()
        // this.applyGetUnpayedEmployeesAction()
        this.applyGetPayedProsAction()
        this.applyGetUnpayedProsAction()
        this.applyGetNotCompletedDirectOrdersAction()
        this.applyGetCustomersInDeptAction()
        this.applyGetEmployeesWalletWallet()
        this.applyGetArchivedDirectOrdersAction()
        this.applyGetBusinessManagersListAction()
        this.applyGetBusinessDeveloperListAction()
        this.applyGetPointsFocauxListAction()
        break

      case 'responsable-commercial':
        this.applyGetDirectOrdersSalaryPayments()
        this.applyGetDateFinanceStatisticsAction()
        this.applyGetMonthFinanceStatisticsAction()
        this.applyGetNumbersStatisticsAction()
        this.applyGetOrdersAction()
        this.applyGetCustomersAction()
        await this.getBestProfessionalsAction()
        await this.getMostRequestedServicesAction()
        await this.getMostRequestedRecurringServicesAction()
        this.applyGetRecurringServicesAction()
        this.applyGetCanceledOrdersAction()
        this.applyGetDirectOrderAction()
        this.applyGetNotCompletedDirectOrdersAction()
        this.applyGetArchivedDirectOrdersAction()
        this.applyGetBusinessManagersListAction()
        break

      case 'responsable-relation-client':
        this.applyGetDirectOrdersSalaryPayments()
        this.applyGetDateFinanceStatisticsAction()
        this.applyGetMonthFinanceStatisticsAction()
        this.applyGetNumbersStatisticsAction()
        this.applyGetOrdersAction()
        this.applyGetCustomersAction()
        await this.getBestProfessionalsAction()
        await this.getMostRequestedServicesAction()
        await this.getMostRequestedRecurringServicesAction()
        this.applyGetRecurringServicesAction()
        this.applyGetCanceledOrdersAction()
        this.applyGetDirectOrderAction()
        this.applyGetNotCompletedDirectOrdersAction()
        this.applyGetArchivedDirectOrdersAction()
        this.applyGetBusinessManagersListAction()
        break

      case 'charge-daffaires':
        this.applyGetDirectOrdersSalaryPayments()
        this.applyGetCustomersAction()
        await this.getBestProfessionalsAction()
        await this.getMostRequestedServicesAction()
        await this.getMostRequestedRecurringServicesAction()
        this.applyGetRecurringServicesAction()
        this.applyGetDirectOrderAction()
        this.applyGetNotCompletedDirectOrdersAction()
        this.applyGetCustomersInDeptAction()
        break

      case 'charge-de-clientele':
        this.applyGetOrdersAction()
        this.applyGetCustomersAction()
        this.applyGetDirectOrderAction()
        await this.getBestProfessionalsAction()
        this.applyGetRecurringServicesAction()
        await this.getMostRequestedServicesAction()
        await this.getMostRequestedRecurringServicesAction()
        this.applyGetNotCompletedDirectOrdersAction()
        this.applyGetCanceledOrdersAction()
        break
      case 'formateur':
        // this.applyGetDirectOrdersUnformedEmployeesAction()
        break
      case 'business-developer-pro':
        this.applyGetServicesAction()
        this.applyGetProfessionalsAction()
        await this.getBestProfessionalsAction()
        await this.getMostRequestedServicesAction()
        await this.getMostRequestedRecurringServicesAction()
        this.applyGetProPerServiceAndAdressAction()
        this.applyGetProAmountPerServiceAction()
        this.applyGetRecurringServicesAction()
        this.applyGetEmployeesAction()
        // this.applyGetPayedEmployeesAction()
        // this.applyGetUnpayedEmployeesAction()
        this.applyGetPayedProsAction()
        this.applyGetUnpayedProsAction()
        this.applyGetEmployeesWalletWallet()
        this.applyGetDirectOrderAction()
        break
      default:
        break
    }
  },

  mounted() {
  },

  methods: {
    ...mapActions('services', [
      'getServicesAction',
      'getMostRequestedServicesAction',
      'getRecurringServicesAction',
      'getMostRequestedRecurringServicesAction',
    ]),

    ...mapActions('orders', [
      'getOrdersAction',
      'getCanceledOrdersAction',
      'getDirectOrdersAction',
      'getNotCompletedDirectOrdersAction',
      'getArchivedDirectOrdersAction',
      'getDirectOrdersSalaryPaymentsAction',
      'getDirectOrdersUnformedEmployeesAction',
    ]),

    ...mapActions('professionals', [
      'getProfessionalsAction',
      'getEmployeesWalletAction',
      'getBestProfessionalsAction',
      'getProPerServiceAndAdressAction',
      'getEmployeesAction',
      'getPayedEmployeesAction',
      'getPayedProsAction',
      'getUnpayedProsAction',
    ]),

    ...mapActions('customers', [
      'getCustomersAction',
      'getCustomersInDeptAction',
    ]),

    ...mapActions('statistics', [
      'getSatisfiedOrderAction',
      'getUnsatisfiedOrderAction',
      'getProAmountAction',
      'getCommissionAction',
      'getCustomerConversionAction',
      'getProAmountPerServiceAction',
      'getDateFinanceStatisticsAction',
      'getMonthFinanceStatisticsAction',
      'getNumbersStatisticsAction',
      'getBusinessDeveloperStatisticsAction',
      'getBusinessManagerStatisticsAction',
    ]),
    ...mapActions('roles', ['getBusinessManagersListAction', 'getBusinessDeveloperListAction']),
    ...mapActions('offerType', [
      'getPointFocalAction',
    ]),

    applyGetDirectOrdersSalaryPayments() {
      this.getDirectOrdersSalaryPaymentsAction()
        .then(() => {
          this.$store.commit('SET_IS_PAYMENT_HISTORY_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_PAYMENT_HISTORY_LOADING', false)
        })
    },
    applyGetDirectOrdersUnformedEmployeesAction() {
      this.getDirectOrdersUnformedEmployeesAction()
        .then(() => {
          this.$store.commit('SET_IS_DIRECT_ORDERS_UNFORMED_EMPLOYEES_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_DIRECT_ORDERS_UNFORMED_EMPLOYEES_LOADING', false)
        })
    },

    applyGetBusinessManagersListAction() {
      this.getBusinessManagersListAction()
        .then(() => {
          this.$store.commit('SET_IS_BUSINESS_MANAGERS_LIST_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_BUSINESS_MANAGERS_LIST_LOADING', false)
        })
    },

    applyGetBusinessManagersStatisticsAction() {
      this.getBusinessManagersListAction()
        .then(() => {
          this.$store.commit('SET_IS_BUSINESS_MANAGERS_LIST_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_BUSINESS_MANAGERS_LIST_LOADING', false)
        })
    },

    applyGetBusinessDeveloperListAction() {
      this.getBusinessDeveloperListAction()
        .then(() => {
          this.$store.commit('SET_IS_BUSINESS_DEVELOPER_LIST_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_BUSINESS_DEVELOPER_LIST_LOADING', false)
        })
    },

    applyGetPointsFocauxListAction() {
      this.getPointFocalAction()
        .then(() => {
          this.$store.commit('SET_IS_POINTS_FOCAUX_LIST_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_POINTS_FOCAUX_LIST_LOADING', false)
        })
    },

    applyGetBusinessDeveloperStatisticsAction() {
      this.getBusinessDeveloperListAction()
        .then(() => {
          this.$store.commit('SET_IS_BUSINESS_DEVELOPER_LIST_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_BUSINESS_DEVELOPER_LIST_LOADING', false)
        })
    },

    applyGetCanceledOrdersAction() {
      this.getCanceledOrdersAction()
        .then(() => {
          this.$store.commit('SET_IS_CANCELED_ORDER_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_CANCELED_ORDER_LOADING', false)
        })
    },

    applyGetProPerServiceAndAdressAction() {
      this.getProPerServiceAndAdressAction()
        .then(() => {
          this.$store.commit(
            'SET_IS_PRO_PER_ADDRESS__AND_SERVICE_LOADING',
            false,
          )
        })
        .catch(() => {
          this.$store.commit(
            'SET_IS_PRO_PER_ADDRESS__AND_SERVICE_LOADING',
            false,
          )
        })
    },

    applyGetProAmountPerServiceAction() {
      this.getProAmountPerServiceAction()
        .then(() => {
          this.$store.commit('SET_PRO_AMOUNT_PER_SERVICE_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_PRO_AMOUNT_PER_SERVICE_LOADING', false)
        })
    },

    applyGetServicesAction() {
      this.getServicesAction()
        .then(() => {
          this.$store.commit('SET_IS_SERVICES_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_SERVICES_LOADING', false)
        })
    },

    applyGetOrdersAction() {
      this.getOrdersAction()
        .then(() => {
          this.$store.commit('SET_IS_ORDERS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_ORDERS_LOADING', false)
        })
    },

    applyGetProfessionalsAction() {
      this.getProfessionalsAction()
        .then(() => {
          this.$store.commit('SET_IS_PROFESSIONALS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_PROFESSIONALS_LOADING', false)
        })
    },

    applyGetCustomersAction() {
      this.getCustomersAction()
        .then(() => {
          this.$store.commit('SET_IS_CUSTOMERS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_CUSTOMERS_LOADING', false)
        })
    },

    applyGetSatisfiedOrderAction() {
      this.getSatisfiedOrderAction()
        .then(() => {
          this.$store.commit('SET_IS_SATISFIED_ORDER_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_SATISFIED_ORDER_LOADING', false)
        })
    },

    applyGetUnsatisfiedOrderAction() {
      this.getUnsatisfiedOrderAction()
        .then(() => {
          this.$store.commit('SET_IS_UNSATISFIED_ORDER_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_UNSATISFIED_ORDER_LOADING', false)
        })
    },

    applyGetProAmountAction() {
      this.getProAmountAction()
        .then(() => {
          this.$store.commit('SET_IS_PRO_AMOUNT_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_PRO_AMOUNT_LOADING', false)
        })
    },

    applyGetCommissionAction() {
      this.getCommissionAction()
        .then(() => {
          this.$store.commit('SET_IS_COMMISSION_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_COMMISSION_LOADING', false)
        })
    },

    applyGetCustomerConversionAction() {
      this.getCustomerConversionAction()
        .then(() => {
          this.$store.commit('SET_IS_CUSTOMERS_COMMISION_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_CUSTOMERS_COMMISION_LOADING', false)
        })
    },

    applyGetDateFinanceStatisticsAction() {
      this.getDateFinanceStatisticsAction()
        .then(() => {
          this.$store.commit('SET_IS_DATE_FINANCE_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_DATE_FINANCE_STATISTICS_LOADING', false)
        })
    },

    applyGetNumbersStatisticsAction() {
      this.getNumbersStatisticsAction()
        .then(() => {
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
    },

    applyGetMonthFinanceStatisticsAction() {
      this.getMonthFinanceStatisticsAction()
        .then(() => {
          this.$store.commit('SET_IS_MONTH_FINANCE_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_MONTH_FINANCE_STATISTICS_LOADING', false)
        })
    },

    applyGetCustomersInDeptAction() {
      this.getCustomersInDeptAction()
        .then(() => {
          this.$store.commit('SET_IS_CUSTOMERS_IN_DEPT_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_CUSTOMERS_IN_DEPT_LOADING', false)
        })
    },

    // Functions to apply Direct Actions
    applyGetDirectOrderAction() {
      this.getDirectOrdersAction()
        .then(() => {
          this.$store.commit('SET_IS_DIRECT_ORDERS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_DIRECT_ORDERS_LOADING', false)
        })
    },

    applyGetRecurringServicesAction() {
      this.getRecurringServicesAction()
        .then(() => {
          this.$store.commit('SET_IS_RECURRING_SERVICES_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_RECURRING_SERVICES_LOADING', false)
        })
    },

    applyGetEmployeesAction() {
      this.getEmployeesAction()
        .then(() => {
          this.$store.commit('SET_IS_EMPLOYEES_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_EMPLOYEES_LOADING', false)
        })
    },

    applyGetPayedEmployeesAction() {
      this.getPayedEmployeesAction()
        .then(() => {
          this.$store.commit('SET_IS_PAYED_EMPLOYEES_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_PAYED_EMPLOYEES_LOADING', false)
        })
    },

    applyGetUnpayedEmployeesAction() {
      this.getUnpayedEmployeesAction()
        .then(() => {
          this.$store.commit('SET_IS_UNPAYED_EMPLOYEES_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_UNPAYED_EMPLOYEES_LOADING', false)
        })
    },

    applyGetPayedProsAction() {
      this.getPayedProsAction()
        .then(() => {
          this.$store.commit('SET_IS_PAYED_PROS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_PAYED_PROS_LOADING', false)
        })
    },

    applyGetUnpayedProsAction() {
      this.getUnpayedProsAction()
        .then(() => {
          this.$store.commit('SET_IS_UNPAYED_PROS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_UNPAYED_PROS_LOADING', false)
        })
    },

    applyGetEmployeesWalletWallet() {
      this.getEmployeesWalletAction()
        .then(() => {
          this.$store.commit('SET_IS_EMPLOYEE_WALLET_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_EMPLOYEE_WALLET_LOADING', false)
        })
    },

    applyGetNotCompletedDirectOrdersAction() {
      this.getNotCompletedDirectOrdersAction()
        .then(() => {
          this.$store.commit('SET_IS_NOT_COMPLETED_DIRECT_ORDERS', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_NOT_COMPLETED_DIRECT_ORDERS', false)
        })
    },

    applyGetArchivedDirectOrdersAction() {
      this.getArchivedDirectOrdersAction()
        .then(() => {
          this.$store.commit('SET_ARCHIVED_DIRECT_ORDERS_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_ARCHIVED_DIRECT_ORDERS_LOADING', false)
        })
    },

  },
}
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.dark-layout {
  .b-overlay-wrap ::v-deep .b-overlay {
    // border: 10px solid red;
    .bg-white {
      background-color: $theme-dark-body-bg !important;
    }
  }
}
</style>
